.opponents {
  background-color: #f4f4f4;
  padding: 0 1rem;
  min-width: 1vw;
  position: relative;
}

.opponents-list {
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-width: 1vw;
}

.opponent {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  padding: 5px;
  background-color: white;
  border-radius: 4px;
  text-align: center;
  flex: 1;
  font-size: 1rem;
  margin: 0.5rem;
  min-width: 15vmin;
  flex-shrink: 0;
  border: 2px solid transparent;
}

.opponent .table-cards {
  background-color: white;
  align-items: center;
  justify-content: center;
}

.opponent p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.opponent .opponent-cards {
  padding: 0.5rem;
}

.opponent .opponent-hand-cards {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.opponent .hand-card-logo {
  width: 3rem;
  height: 3rem;
}

.opponent.is-turn {
  border: 2px solid orange;
}
