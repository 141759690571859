.start-game {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.num-bots-input {
  display: flex;
  flex-direction: column;
}
