.game-state {
  max-height: calc(90dvh - 40px);
  overflow: visible;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.game-state-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.player-last-message {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.player-last-message * {
  font-size: 1.5rem;
}

.your-turn {
  text-transform: uppercase;
  font-weight: bold;
  padding: 1rem;
  margin: 0;
  color: white;
  background-color: #607d8b;
  text-align: center;
}

.opponents-turn {
  text-transform: uppercase;
  font-weight: bold;
  padding: 1rem;
  margin: 0;
  color: white;
  background-color: gray;
  text-align: center;
}

.turn-indicator {
  display: flex;
  justify-content: space-between;
}

.turn-indicator > .spacer,
.turn-indicator > .emoji-btn {
  width: 50px;
}

.emoji-btn-container {
  position: relative;
}

.emoji-btn {
  display: flex;
  background-color: transparent;
  justify-content: right;
}

.emoji-picker {
  background-color: whitesmoke;
  color: black;
  text-align: center;
  padding: 8px 0;
  border-radius: 6px;
  border: lightgray solid 1px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  right: 0;
  width: 160px;
}

.emoji-picker::after {
  content: ' ';
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  right: 10px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: whitesmoke transparent transparent transparent;
}

.emoji-picker button {
  background-color: transparent;
}

.emoji-btn-core {
  font-size: 1.5rem;
}
