.errors {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.error {
  background-color: rgba(255, 0, 0, 1);
  display: inline-flex;
  justify-content: space-around;

  margin: 1rem;
  margin-bottom: 0;
  border-radius: 0.4rem;
  box-shadow: #000000 0 0 0.5rem;
}

.error-fade {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.error > * {
  margin: 1em;
}

.error > p {
  width: 80%;
}

.error > button {
  background-color: #00000000;
  border: none;
}
