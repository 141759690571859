.player {
  display: contents;
}

.table-cards {
  margin-top: auto;
  justify-content: center;
  width: 100%;
  display: flex;
  background-color: #f4f4f4;
}

.player-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2.8vmin;
}

.swap-cards,
.pickup-cards,
.play-cards,
.sort-cards {
  color: #090909;
  padding: 0.7em 1.7em;
  margin: 0.7em 6px;
  border-radius: 0.5em;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  box-shadow:
    6px 6px 12px #c5c5c5,
    -6px -6px 12px #ffffff;
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 40%;
}
.swap-cards:active,
.pickup-cards:active,
.play-cards:active,
.sort-cards:active {
  color: #666;
  box-shadow:
    inset 4px 4px 12px #c5c5c5,
    inset -4px -4px 12px #ffffff;
}
