.lobby {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
}

.lobby .players {
    margin: 1rem 2rem;
}

.lobby .game-config {
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem;
    flex-grow: 1;
}

.lobby .start-game-btn {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.lobby .start-game-btn .btn {
    width: 100%;
}

.lobby .replace-bot {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.advanced-container {
    padding-top: 1em;
}

.check-container {
    display: flex;
    justify-content: space-between;
}

.check-container input {
    width: auto;
}