.deck {
  width: 45%;
  display: flex;
  flex-direction: row-reverse;
}

.deck-card-wrapper {
  overflow: visible;
}

.deck-card-wrapper {
  max-width: 0;
}
