.chat {
    padding: 2em;
    height: calc(100% - 4em);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: left;
}


.chat .messages {
    flex-shrink: 1;
    overflow-y: auto;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
}

.chat .message {
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: column;
}

.chat .message .author {
    font-size: 0.8em;
    margin-bottom: 0.25em;
}

.chat .message .message-contents {
    background-color: whitesmoke;
    overflow-wrap: break-word;
    border-radius: 5px;
    padding: 0.5em;
    width: fit-content;
    font-size: 1.2em;
    max-width: 90%;
}

.chat .send {
    width: 100%;
    display: flex;
}

.chat .send-button {
    padding: 0 0.5em;
    flex-shrink: 0;
    width: fit-content;
}

.chat input {
    flex-shrink: 1;
    flex-grow: 1;
    margin-bottom: 0;
    margin-right: 1em;
}

.chat .unseen-messages {
    position: sticky;
    bottom: 0;
    align-self: center;
    background-color: orange;
    color: white;
    border-radius: 5px;
    padding: 0.5em;
    width: fit-content;
    margin-bottom: 1em;
}