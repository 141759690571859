.lobby-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
}


.lobby-list .bottom-buttons {
  flex-shrink: 0;
  display: flex;
  flex-basis: 1;
  flex-direction: row;
}

.bottom-buttons .new-game-btn {
  margin: 1rem 2rem;
  flex-shrink: 0;
  flex-grow: 1;
}
.bottom-buttons .leaderboard-btn {
  margin: 1rem 2rem;
  flex-shrink: 0;
  flex-grow: 1;
}

.lobby-list .lobbies {
  margin: 1rem 2rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}
