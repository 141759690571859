.lobby-list-item {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;

  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  padding: 0.5rem 2rem;
  border-radius: 4px;
  background-color: #f4f4f4;
  margin-bottom: 0.5rem;
  border: 2px solid transparent;
}

.lobby-list-item .lobby-name {
  flex-grow: 1;
}

.lobby-list-item .player-num {
  display: flex;
  align-items: center;
}
