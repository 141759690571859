.reconnect {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #00000046;
    z-index: 1000;
}

.reconnect-box {
    background-color: whitesmoke;
    border-radius: 5px;
    padding: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
}