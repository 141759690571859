.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  padding: 1rem;
  border-radius: 3px;
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

.modal .modal-body {
  margin-bottom: 2rem;
}
