.home-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.name-input {
  text-align: left;
  margin: 0rem 2rem;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
}

.play-btn {
  margin: 1rem 2rem;
}
