.ephemeral {
    transition: opacity 0s;
    opacity: 1;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 8px;
    pointer-events: none;
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ephemeral.emoji-no-border {
    background-color: transparent;
    border: none;
    padding: 0;
}

.ephemeral button {
    border: none;
    background-color: transparent;
}

.ephemeral.fade {
    opacity: 0;
    transition: opacity 1s;
}
