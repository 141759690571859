.game-over {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #00000046;
  z-index: 1000;
}

.game-over h1 {
  text-align: center;
  margin-top: 0;
}

.game-over-box {
  background-color: whitesmoke;
  border-radius: 5px;
  padding: 2em;
}

.game-over .game-over-ordinal {
  margin-bottom: 0.5em;
  width: 50%;
  padding-right: 5em;
}

.game-over .game-over-player-name {
  margin-bottom: 0.5em;
  white-space: nowrap;
  text-align: end;
}

.game-over .game-over-leave-btn {
  width: 100%;
  margin-top: 1em;
}

.game-over .game-over-position {
  display: flex;
  justify-content: space-between;
}
