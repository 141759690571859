.table-cards .hidden-cards {
  grid-row: 1;
  grid-column: 1;
}
.table-cards .visible-cards {
  z-index: 1;
  grid-row: 1;
  grid-column: 1;
}
.visible-cards {
  pointer-events: none;
}
.table-cards-container {
  display: grid;
}
