.action-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f4f4;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.action-bar .back {
  width: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
}
