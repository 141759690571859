.game-top {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 40px;
  flex-shrink: 0;
}

.game-top > * {
  flex-grow: 1;
  flex-basis: 100%;
  border-bottom: 2px solid gray;
}

.game-top > .active {
  border-bottom: 2px solid orange;
}

.game-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tab-content {
  overflow: auto;
  flex-grow: 1;
}

.inactive-tab {
  display: none;
  height: 100%;
}

.tab {
  position: relative;
  height: 100%;
}

.settings-tab {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  padding: 20px;
  justify-content: center;
}

.inactive-tab {
  display: none;
  height: 100%;
}

/* Larger than mobile */
@media (min-width: 900px) {
  .tab {
    height: 100%;
    flex-grow: 1;
  }

  .inactive-tab {
    display: block;
  }

  .game-tab {
    flex-basis: 600%;
    max-width: 60%;
    border-right: lightgray solid 1px;
  }

  .game-state {
    max-height: 90dvh !important;
  }

  .chat-tab {
    flex-basis: 400%;
    max-width: 40%;
    max-height: calc(100% - 3.5rem);
    align-self: end;
  }

  .tab-content {
    display: flex;
  }

  .game-top {
    display: none;
  }

  .settings-tab {
    max-width: 0;
    overflow: visible;
    flex-basis: 0;
    padding: 0;
  }

  .settings-tab > * {
    position: fixed;
    top: 12px;
    left: calc(60% + 12px);
    width: calc(40% - 24px);
  }
}

/* Larger than mobile */
@media (min-width: 1450px) {
  .tab {
    flex-basis: 100%;
  }

  .game-tab {
    max-width: 75%;
    flex-basis: 300%;
  }

  .chat-tab {
    max-width: 25%;
  }

  .settings-tab > * {
    position: fixed;
    top: 24px;
    left: calc(75% + 24px);
    width: calc(25% - 48px);
  }
}
