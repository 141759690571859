.pile {
  display: contents;
}

.pile-card-container {
  margin-left: 1vmin;
  display: flex;
  flex-direction: row;
  max-width: 55%;
}

.pile-card-wrapper {
  overflow: visible;
}

.pile-card-wrapper-hidden {
  width: 1.5vmin;
}

.pile-card-wrapper:not(last-child) {
  max-width: 2vmin;
}
