.leaderboard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
  padding: 1rem 2rem;
}

.leaderboard .new-game-btn {
  margin: 1rem 2rem;
  flex-shrink: 0;
}

.leaderboard table{
  text-align: center;
}
